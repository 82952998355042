import React, { useState, useLayoutEffect } from "react"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import en_team from "../../content/en_team.json"
import de_team from "../../content/de_team.json"
import Layout from "../components/layout"
import colors from "../components/colors"
import Newsletter from "../components/Newsletter"
import ContactBanner from "../components/ContactBanner"
//import desktopStyles from '../components/desktopStyles'
//import mobileStyles from '../components/mobileStyles'

import ReactMarkdown from "react-markdown"

const About = props => {
  const { title, team } = props.store.language==='en' ? en_team : de_team
  const styles = props.store.device === "desktop" ? desktopStyles : mobileStyles
  return (
    <Layout>
    <Helmet>
          <title>{'LT | About'}</title>
        </Helmet>
    <div style={{position:'relative'}}>
        <img
        style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
        src={en_team.teamVisual}
        alt="Fans cheering"
      />
      <div style={styles.background}>
        <div style={styles.pageContainer}>
          <div style={styles.breadCrumbSection}>
            <Link to={"/"} style={styles.link}>
              Home
            </Link>{" "}
            /{" About"}
          </div>
          <div style={styles.headerSection}>
            <div style={styles.headerContainer}>
              <h2 style={styles.header}>Liquiditeam</h2>
              <div style={styles.generalInfo}>
              <ReactMarkdown
              parserOptions={{ commonmark: true }}
                  source={title}
                  escapeHtml={false}
                />
              </div>
            </div>
            <div style={styles.teamContainer}>
              {team.map((member, index) => (
                <Member key={index} teamMember={member} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ContactBanner />
      <Newsletter />
      </div>
    </Layout>
  )
}
export default inject("store")(observer(About))

const Member = inject('store')(observer((props) => {
  const styles = props.store.device === "desktop" ? desktopStyles : mobileStyles
  const refContainer = React.useRef(null)
  const parentElSpecs = useWindowSize(refContainer)
  const {
    name,
    position,
    description,
    photo,
    linkedIn,
    twitter,
    medium,
  } = props.teamMember
  return (
    <div style={styles.outerMemberContainer}>
      <div
        ref={refContainer}
        style={{
          width: "100%",
          clipPath: props.store.device==='desktop'?`polygon(100% 0%, 0% 0%, 0% 100%, ${parentElSpecs[0] -
            parentElSpecs[1] * 0.2}px 100% , 100% 80%)`:`polygon(100% 0%, 0% 0%, 0% 100%, ${parentElSpecs[0] -
            parentElSpecs[1] * 0.1}px 100% , 100% 90%)`,
        }}
      >
        <div style={styles.innerMemberContainer}>
          <img src={photo} style={styles.image} alt={name} />
          <div style={styles.infoContainer}>
            <div style={styles.memberHeaderContainer}>
              <h2 style={styles.memberHeader}>{name}</h2>
              <div>
                {!linkedIn || linkedIn === "" ? null : (
                  <a href={linkedIn}>
                    <img
                      src="/assets/linkedin.png"
                      style={styles.smLogo}
                      alt="link to linked in"
                    />
                  </a>
                )}
                {!twitter || twitter === "" ? null : (
                  <a href={twitter}>
                    <img
                      src="/assets/twitter.png"
                      style={styles.smLogo}
                      href={twitter}
                      alt="link to twitter"
                    />
                  </a>
                )}
                {!medium || medium === "" ? null : (
                  <a href={medium}>
                    <img
                      src="/assets/medium.png"
                      style={styles.smLogo}
                      href={medium}
                      alt="medium"
                    />
                  </a>
                )}
              </div>
            </div>
            <p style={styles.memberTags}>{position}</p>
            <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} style={styles.memberDescription}>{description}</ReactMarkdown>
          </div>
        </div>
      </div>
    </div>
  )
}))
function useWindowSize(refContainer) {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([
        refContainer.current.offsetWidth,
        refContainer.current.offsetHeight,
      ])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
    // eslint-disable-next-line
  }, [])
  return size
}

const desktopStyles = {
  background: { backgroundColor: colors.white, marginTop:'-10px' },
  pageContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { padding: "50px 0px" },
  headerContainer: { display: "flex", marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  generalInfo: {
    width: '70%'
  },
  teamContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  outerMemberContainer: { width: "100%", margin: "25px 0px" },
  innerMemberContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "25px",
    backgroundColor: colors.lightgrey,
    margin: "0px 0px",
  },
  image: {
    height: '225px',
    width: '225px',
    maxHeight: "225px",
    maxWidth: "225px",
    objectFit: "cover",
  },
  infoContainer: { margin: "0px 25px" },
  memberHeaderContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  memberHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  smLogo: { width: "30px", height: "30px", margin: "0px 5px" },
  memberTags: { color: colors.teamTags },
  memberDescription: { color: "#4F4F4F" },
}

const mobileStyles = {
  background: { backgroundColor: colors.white, marginTop:'-10px' },
  pageContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { padding: "50px 0px" },
  headerContainer: { display: "flex",marginBottom: '1.45rem', flexDirection:'column', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  generalInfo: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  teamContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  outerMemberContainer: { width: "100%", margin: "25px 0px" },
  innerMemberContainer: {
    display: "flex",
    flexDirection: "column",
    
    padding: "25px 25px 70px",
    backgroundColor: colors.lightgrey,
    margin: "0px 0px",
  },
  image: {
    alignSelf:'center',
    //maxHeight: "225px",
    //maxWidth: "225px",
    objectFit: "cover",
  },
  infoContainer: { margin: "25px 0px" },
  memberHeaderContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  memberHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  smLogo: { width: "30px", height: "30px", margin: "0px 5px" },
  memberTags: { color: colors.teamTags },
  memberDescription: { color: "#4F4F4F" },
}
